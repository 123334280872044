import React from 'react'
import './Mision.css'
import bgSalaUrbanaMobile from '../../assets/images/mision/bg-sala-urbana-mobile.svg'
import bgVisionMobile from '../../assets/images/mision/bg-vision-mobile.svg'

import bgSalaUrbana from '../../assets/images/mision/bg-sala-urbana.svg'
import bgVision from '../../assets/images/mision/bg-vision.svg'

export default function Mision() {
  return (
    <div className='Mision'>
    
      {/* mobile */}
      <div className='block lg:hidden'>
        
        <div className='w-full py-8'>
          <div className='flex justify-end'>
            <div className='w-10/12 relative'>
              <div className='absolute top-6 md:top-24'>
                <div className='w-full flex flex-col justify-center items-center h-full'>
                  <div className='title w-4/5 pl-16 md:pl-32'>
                    Sala Urbana
                  </div>
                  <div className='description w-4/5 pl-16 md:pl-32 pt-4'>
                    Creemos en la diversidad y la pasión por la música, arte y entretenimiento en todas sus formas.
                  </div>
                </div> 
              </div>
              <img src={bgSalaUrbanaMobile} alt='sala urbana' className='w-full' />
            </div>
          </div>
        </div>

        <div className='w-full pb-8'>
          <div className='flex justify-start'>
            <div className='w-10/12 relative'>
              <div className='absolute top-6 md:top-28'>
                <div className='w-full flex justify-end md:-mx-8 pr-12'>
                  <div className='w-4/5'>
                    <div className='title'>
                      NUESTRA VISIÓN
                    </div>
                    <div className='description pt-4'>
                      Es simple, proporcionar un espacio vibrante para disfrutar de una amplia variedad de entretenimiento en vivo.
                    </div>
                  </div>
                </div> 
              </div>
              <img src={bgVisionMobile} alt='Visión' className='w-full' />
            </div>
          </div>
        </div>
      </div>

      {/* desktop */}
      <div className='hidden lg:block'>
        <div className='w-full py-8'>
          <div className='flex justify-end'>
            <div className='w-10/12 relative'>
              <img src={bgSalaUrbana} alt='Sala Urbana' className='w-full' />
              <div className='absolute top-0 w-full flex justify-center items-center h-full'>
                <div className='w-10/12 flex items-center h-full'>
                  <div className='w-4/12'>
                    <div className='title w-2/3 pl-12'>
                      Sala Urbana
                    </div>
                  </div>
                  <div className='w-2/12'>&nbsp;</div>
                  <div className='w-5/12'>
                    <div className='description'>
                      Creemos en la diversidad y la pasión por la música, arte y entretenimiento en todas sus formas.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full pb-8'>
          <div className='flex justify-start'>
            <div className='w-10/12 relative'>
              <img src={bgVision} alt='Visión' className='w-full' />
              <div className='absolute top-0 w-full flex justify-center items-center h-full'>
                <div className='w-10/12 flex items-center h-full'>
                  <div className='w-5/12'>
                    <div className='description'>
                      Es simple, proporcionar un espacio vibrante para disfrutar de una amplia variedad de entretenimiento en vivo.
                    </div>
                  </div>
                  <div className='w-2/12'>&nbsp;</div>
                  <div className='w-4/12'>
                    <div className='title title2'>
                      NUESTRA VISIÓN
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}