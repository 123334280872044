import React from 'react'
import './Servicios.css'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import barras from '../../assets/images/servicios/barras.png'
import barrasIcon from '../../assets/images/servicios/barras-icon.svg'
import taquillas from '../../assets/images/servicios/taquilla.png'
import taquillaIcon from '../../assets/images/servicios/taquilla-icon.svg'
import recepcion from '../../assets/images/servicios/recepcion.png'
import recepcionIcon from '../../assets/images/servicios/recepcion-icon.svg'
import guardaropa from '../../assets/images/servicios/guardarropa.png'
import guardaropaIcon from '../../assets/images/servicios/guardarropa-icon.svg'
import enfermeria from '../../assets/images/servicios/enfermeria.png'
import enfermeriaIcon from '../../assets/images/servicios/enfermeria-icon.svg'
import sanitarios from '../../assets/images/servicios/sanitarios.png'
import sanitariosIcon from '../../assets/images/servicios/sanitarios-icon.svg'
import sanitariosServicio from '../../assets/images/servicios/sanitarios-servicio.png'
import sanitariosServicioIcon from '../../assets/images/servicios/sanitarios-servicio-icon.svg'

import mezzanine from '../../assets/images/servicios/mezzanine.png'
import mezzanineIcon  from '../../assets/images/servicios/mezzanine-icon.svg'
import oficinaProd from '../../assets/images/servicios/oficina_produccion.png'
import oficinaProdIcon from '../../assets/images/servicios/oficina_produccion-icon.svg'
import cabinaProd from '../../assets/images/servicios/cabina-produccion.png'
import cabinaProdIcon from '../../assets/images/servicios/cabina-produccion-icon.svg'
import camerinoPrincipal from '../../assets/images/servicios/camerino-principal.png'
import camerinoPrincipalIcon from '../../assets/images/servicios/camerino-principal-icon.svg'
import camerinoSecundario from '../../assets/images/servicios/camerinos-secundarios.png'
import camerinoSecundarioIcon from '../../assets/images/servicios/camerinos-secundarios-icon.svg'
import valet from '../../assets/images/servicios/valet-parking.png'
import valetIcon from '../../assets/images/servicios/valet-parking-icon.svg'




const serviciosItems = [
  {
    image: barras,
    icon: barrasIcon,
    title: '6 barras',
    description: '(Fregaderos, JockeyBar, almacén, refrigeradores).'
  },
  {
    image: taquillas,
    icon: taquillaIcon,
    title:'taquilla',
    description:'',
    },
    {
    image: recepcion,
    icon: recepcionIcon,
    title:'recepción',
    description:'',
    },
    {
    image: guardaropa,
    icon: guardaropaIcon,
    title:'guardarropa',
    description:'',
    },
    {
    image: enfermeria,
    icon: enfermeriaIcon,
    title:'ENFERMERÍA',
    description:'',
    },
    {
    image: sanitarios,
    icon: sanitariosIcon,
    title:'sanitarios',
    description:'',
    },
    {
    image: sanitariosServicio,
    icon: sanitariosServicioIcon,
    title:'sanitarios de servicio',
    description:'',
    },
    {
    image: mezzanine,
    icon: mezzanineIcon,
    title:'mezzanine',
    description:'',
    },
    {
    image: oficinaProd,
    icon: oficinaProdIcon,
    title:'oficina de producción',
    description:'',
    },
    {
    image: cabinaProd,
    icon: cabinaProdIcon,
    title: 'cabina de producción',
    description:'',
    },
    {
    image: camerinoPrincipal,
    icon: camerinoPrincipalIcon,
    title:'1 CAMERINO PRINCIPAL',
    description:'(Baño completo)',
    },
    {
    image: camerinoSecundario,
    icon: camerinoSecundarioIcon,
    title: '4 CAMERINOS SECUNDARIOS',
    description:'(Baños H M)',
    },
    {
    image: valet,
    icon: valetIcon,
    title:'valet parking',
    description:'',
    }
];
const breakpoints = {
  1024: {
    perPage: 1,
  },
  768: {
    perPage: 1,
  },
  640: {
    perPage: 1,
  },
};


const options = {
  type: 'loop', // Enable loop playback
  perPage: 1, // Show 1 slides at a time
  arrows: true, // Show navigation arrows
  breakpoints,
  perMove   : 1,
  pagination: false,
  cloneStatus:false,

};

export default function Servicios() {
  return (
    <div className='Servicios'>
      <div className='flex flex-col justify-center w-full lg:pt-16 lg:pb-8'>
        <div className='title'>
          Servicios
        </div>

        <div className='pt-8 w-full'>
        <Splide options={options}>
          {
            serviciosItems.map((item, index) => (
              <SplideSlide key={ index } >
                <img src={item.image} alt={item.title} className='w-full object-cover'/>
                <div className="absolute inset-0 flex flex-col items-center h-full justify-center mx-auto w-96">
                    <img src={item.icon} alt='6 barras' className='w-10 md:w-20 lg:w-40 mb-4 lg:mb-8' />
                    <h2 className="slider-title mb-2 lg:mb-4">{item.title}</h2>
                    <p className='slider-description'>{item.description}</p>
                </div>
                </SplideSlide>
            ))
          }
          </Splide>
        </div>
      </div>
    </div>
  )
}