import React from 'react'
import './Eventos.css'
import NextEvents from './NextEvents/NextEvents'
import PastEvents from './PastEvents/PastEvents'

export default function Eventos() {
  return (
    <div className='Eventos py-6'>
      <div className='eventos-title py-8'>
        Próximos eventos
      </div>
      <div className='pt-6 next-events-width'>
        <NextEvents />

      </div>

    </div>
  )
}
