import React, { useCallback, useState } from 'react'
import './Menu.css'
import logoMenu from '../../assets/images/logo-menu.png'

export default function Menu() {

  const [showMenu, setShowMenu] = useState(false);

  const handleClickMenu = useCallback(() => {
    let currentMenuState = showMenu;
    console.log('currentMenuState: ', currentMenuState);
    setShowMenu(!currentMenuState);
  }, [showMenu]);

  return (
    <div className='Menu fixed top-0 w-full'>
        <nav className="bg-transparent sticky top-0">

            <div className="mx-auto px-2 sm:px-6 lg:px-8">

                <div className="relative flex h-16 items-center justify-between">
                  <div className='d-flex justify-center items-center w-full sm:hidden'>
                    <img src={logoMenu} alt='Logo Sala Urbana' className='m-auto' />
                  </div>
                  <div
                    className="absolute inset-y-0 right-0 flex items-center sm:hidden"
                  >
                      <button 
                        type="button" 
                        className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" 
                        aria-controls="mobile-menu" 
                        aria-expanded="false"
                        onClick={handleClickMenu}
                      >
                        <span className="absolute -inset-0.5"></span>
                        <span className="sr-only">Open main menu</span>
                        <svg
                          className="block h-6 w-6" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          strokeWidth="1.5" 
                          stroke="currentColor" 
                          aria-hidden="true"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                          {/* <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg> */}
                      </button>
                  </div>
                  <div className="flex flex-1 items-center justify-center w-full">
                      <div className="hidden sm:ml-0 sm:block w-full">
                        <div className="flex justify-between w-full">
                            <a href="#next" className="text-white px-3 py-2 text-sm nav-item-text">
                              PRÓXIMOS EVENTOS
                            </a>
                            <a href="#services" className="text-white px-3 py-2 text-sm nav-item-text">
                              SERVICIOS
                            </a>
                            <a href="/" className=" px-3 py-2 text-sm font-medium">
                              <img src={logoMenu} alt='Logo Sala Urbana' />
                            </a>
                            <a href="#faq" className="text-white px-3 py-2 text-sm nav-item-text" aria-current="page">
                              PREGUNTAS FRECUENTES
                            </a>
                            <a href="#contact" className="text-white px-3 py-2 text-sm nav-item-text">
                              CONTACTO
                            </a>
                        </div>
                      </div>
                  </div>
                </div>
            </div>

            <div className="sm:hidden" id="mobile-menu">
              {
                showMenu && 
                <div className="space-y-1 px-2 pb-3 pt-2 flex flex-col text-right">
                  <a href="#NEXT" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                    PRÓXIMOS EVENTOS
                  </a>
                  <a href="#services" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                    SERVICIOS
                  </a>
                  <a href="#faq" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium" aria-current="page">
                    PREGUNTAS FRECUENTES
                  </a>
                  <a href="#contact" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                    CONTACTO
                  </a>
                </div>
              }
            </div>
        </nav>

    </div>
  )
}
