import artWeek1 from '../assets/images/pastEvents/artWeek2024/1.JPG'
import artWeek2 from '../assets/images/pastEvents/artWeek2024/2.JPG'
import artWeek3 from '../assets/images/pastEvents/artWeek2024/3.jpg'
import artWeek4 from '../assets/images/pastEvents/artWeek2024/4.jpg'
import artWeek5 from '../assets/images/pastEvents/artWeek2024/5.jpg'
import artWeek6 from '../assets/images/pastEvents/artWeek2024/6.jpg'
import artWeek7 from '../assets/images/pastEvents/artWeek2024/7.jpg'

import borisBrecha1 from '../assets/images/pastEvents/borisBrecha/1.jpg'
import borisBrecha2 from '../assets/images/pastEvents/borisBrecha/2.jpg'
import borisBrecha3 from '../assets/images/pastEvents/borisBrecha/3.jpg'
import borisBrecha4 from '../assets/images/pastEvents/borisBrecha/4.jpg'

import camelphat1 from '../assets/images/pastEvents/Camelphat/1.jpg'
import camelphat2 from '../assets/images/pastEvents/Camelphat/2.jpg'
import camelphat3 from '../assets/images/pastEvents/Camelphat/3.jpg'
import camelphat4 from '../assets/images/pastEvents/Camelphat/4.jpg'
import camelphat5 from '../assets/images/pastEvents/Camelphat/5.jpg'
import camelphat6 from '../assets/images/pastEvents/Camelphat/6.jpg'

import hernanCattaneo1 from '../assets/images/pastEvents/hernanCattaneo/1.png'
import hernanCattaneo2 from '../assets/images/pastEvents/hernanCattaneo/2.png'

import julianJewel1 from '../assets/images/pastEvents/JulianJeweil/1.jpg'
import julianJewel2 from '../assets/images/pastEvents/JulianJeweil/2.jpg'
import julianJewel3 from '../assets/images/pastEvents/JulianJeweil/3.jpg'
import julianJewel4 from '../assets/images/pastEvents/JulianJeweil/4.jpg'

import roger1 from '../assets/images/pastEvents/rogerSanchez/1.jpg'
import roger2 from '../assets/images/pastEvents/rogerSanchez/2.jpg'
import roger3 from '../assets/images/pastEvents/rogerSanchez/3.jpg'
import roger4 from '../assets/images/pastEvents/rogerSanchez/4.jpg'
import roger5 from '../assets/images/pastEvents/rogerSanchez/5.jpg'
import roger6 from '../assets/images/pastEvents/rogerSanchez/6.jpg'
import roger7 from '../assets/images/pastEvents/rogerSanchez/7.jpg'
import roger8 from '../assets/images/pastEvents/rogerSanchez/8.jpg'
import roger9 from '../assets/images/pastEvents/rogerSanchez/9.jpg'

import strangeHuman1 from '../assets/images/pastEvents/StrageHuman/1.jpg'
import strangeHuman2 from '../assets/images/pastEvents/StrageHuman/2.jpg'
import strangeHuman3 from '../assets/images/pastEvents/StrageHuman/3.jpg'
import strangeHuman4 from '../assets/images/pastEvents/StrageHuman/4.jpg'
import strangeHuman5 from '../assets/images/pastEvents/StrageHuman/5.jpg'

import yeriMua1 from '../assets/images/pastEvents/yeriMua/1.JPG'
import yeriMua2 from '../assets/images/pastEvents/yeriMua/2.JPG'

import accept1 from '../assets/images/pastEvents/Accept/1.jpg'
import accept2 from '../assets/images/pastEvents/Accept/2.jpg'
import accept3 from '../assets/images/pastEvents/Accept/3.jpg'
import accept4 from '../assets/images/pastEvents/Accept/4.jpg'
import accept5 from '../assets/images/pastEvents/Accept/5.jpg'

import nicoMoreno1 from '../assets/images/pastEvents/NicoMoreno/1.jpg'
import nicoMoreno2 from '../assets/images/pastEvents/NicoMoreno/2.jpg'
import nicoMoreno3 from '../assets/images/pastEvents/NicoMoreno/3.jpg'
import nicoMoreno4 from '../assets/images/pastEvents/NicoMoreno/4.jpg'

import rhapsodyOfFire1 from '../assets/images/pastEvents/RhapsodyOfFire/1.jpg'
import rhapsodyOfFire2 from '../assets/images/pastEvents/RhapsodyOfFire/2.jpg'
import rhapsodyOfFire3 from '../assets/images/pastEvents/RhapsodyOfFire/3.jpg'
import rhapsodyOfFire4 from '../assets/images/pastEvents/RhapsodyOfFire/4.jpg'
import rhapsodyOfFire5 from '../assets/images/pastEvents/RhapsodyOfFire/5.jpg'

import hammerFall1 from '../assets/images/pastEvents/HammerFall/1.jpg'
import hammerFall2 from '../assets/images/pastEvents/HammerFall/2.jpg'
import hammerFall3 from '../assets/images/pastEvents/HammerFall/3.jpg'
import hammerFall4 from '../assets/images/pastEvents/HammerFall/4.jpg'
import hammerFall5 from '../assets/images/pastEvents/HammerFall/5.jpg'
import hammerFall6 from '../assets/images/pastEvents/HammerFall/6.jpg'
import hammerFall7 from '../assets/images/pastEvents/HammerFall/7.jpg'

import mathame1 from '../assets/images/pastEvents/Mathame/1.jpg'
import mathame2 from '../assets/images/pastEvents/Mathame/2.jpg'
import mathame3 from '../assets/images/pastEvents/Mathame/3.jpg'
import mathame4 from '../assets/images/pastEvents/Mathame/4.jpg'

import miuraBoxing1 from '../assets/images/pastEvents/MiuraBoxing/1.JPG'
import miuraBoxing2 from '../assets/images/pastEvents/MiuraBoxing/2.JPG'
import miuraBoxing3 from '../assets/images/pastEvents/MiuraBoxing/3.JPG'
import miuraBoxing4 from '../assets/images/pastEvents/MiuraBoxing/4.JPG'
import miuraBoxing5 from '../assets/images/pastEvents/MiuraBoxing/5.JPG'

import savageAndShe1 from '../assets/images/pastEvents/SavageAndShe/1.png'
import savageAndShe2 from '../assets/images/pastEvents/SavageAndShe/2.png'
import savageAndShe3 from '../assets/images/pastEvents/SavageAndShe/3.png'

export const pastEventsImages = [
    {
    event: 'artWeek2024',
        images: [
            artWeek1,
            artWeek2,
            artWeek3,
            artWeek4,
            artWeek5,
            artWeek6,
            artWeek7
        ]
    },
    {
        event: 'borisBrecha',
        images: [
            borisBrecha1,
            borisBrecha2,
            borisBrecha3,
            borisBrecha4
        ]
    },
    {
        event: 'camelphat',
        images: [
            camelphat1,
            camelphat2,
            camelphat3,
            camelphat4,
            camelphat5,
            camelphat6
        ]
    },
    {
        event: 'hernanCattaneo',
        images: [
            hernanCattaneo1,
            hernanCattaneo2,
        ]
    },
    {
        event: 'julianJewel',
        images: [
            julianJewel1,
            julianJewel2,
            julianJewel3,
            julianJewel4
        ]
    },
    {
        event: 'miuraBoxing',
        images: [
            miuraBoxing1,
            miuraBoxing2,
            miuraBoxing3,
            miuraBoxing4,
            miuraBoxing5
        ]
    },
    {
        event: 'roger',
        images: [
            roger1,
            roger2,
            roger3,
            roger4,
            roger5,
            roger6,
            roger7,
            roger8,
            roger9
        ]
    },
    {
        event: 'strangeHuman',
        images: [
            strangeHuman1,
            strangeHuman2,
            strangeHuman3,
            strangeHuman4,
            strangeHuman5
        ]
    },
    {
        event: 'yeriMua',
        images: [
            yeriMua1,
            yeriMua2
        ]
    },
    {
        event: 'Accept',
        images: [
            accept1,
            accept2,
            accept3,
            accept4,
            accept5
        ]
    },
    {
        event: 'NicoMoreno',
        images: [
            nicoMoreno1,
            nicoMoreno2,
            nicoMoreno3,
            nicoMoreno4
        ]
    },
    {
        event: 'RhapsodyOfFire',
        images: [
            rhapsodyOfFire1,
            rhapsodyOfFire2,
            rhapsodyOfFire3,
            rhapsodyOfFire4,
            rhapsodyOfFire5
        ]
    },
    {
        event: 'HammerFall',
        images: [
            hammerFall1,
            hammerFall2,
            hammerFall3,
            hammerFall4,
            hammerFall5,
            hammerFall6,
            hammerFall7
        ]
    },
    {
        event: 'Mathame',
        images: [
            mathame1,
            mathame2,
            mathame3,
            mathame4
        ]
    },
    {
        event: 'SavageAndShe',
        images:[
            savageAndShe1,
            savageAndShe2,
            savageAndShe3
        ]
    }
];
